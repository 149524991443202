<template>
  <div class="newsDetails">
    <crumbs :item="title" />
    <div class="main" v-loading="loading" element-loading-text="拼命加载中">
      <div class="title">
        <h3>
          {{ newsTitle }}
        </h3>
        <p class="time">{{ time }}</p>
      </div>
      <!-- <div v-html="content"></div> -->
      <div>
        <Editor :readonly="true" :content="content" :height="0" />
      </div>
    </div>
    <!--    <div class="line"></div>-->
    <!--    <div class="moreNews">-->
    <!--      <div>更多新闻公告</div>-->
    <!--      <div class="more">-->
    <!--        <div v-for="item in moreNews" :key="item.title">-->
    <!--          <img src="@/static/images/right.png" alt="" />-->
    <!--          <p>{{ item.title }}</p>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
import Editor from "@/components/TinyMce";
// import store from "storejs";
import crumbs from "@/components/crumbs.vue";
import { newsDetailPre } from "@/api/news";
// const news = [
//   {
//     title:
//       "《2022年安联全球财富报告》：2021年中国家庭金融资产增长12.2%创历史新记录",
//     children: [
//       "近日，安联集团公布了最新《2022年安联全球财富报告》，对近60个国家和地区的家庭资产和债务状况进行了详细分析。这是安联集团连续第13年发布该全球调研报告。",
//       "2021年全球金融资产连续第三年实现两位数增长达到233万亿欧元",
//       "2021年货币政策推动了股市繁荣，全球金融资产连续第三年实现两位数增长，达到233万亿欧元。在过去的三年里，全球私人财富增长了60万亿欧元，相当于在全球金融体系中增加了两个欧元区。",
//       "股市的繁荣对2021年全球财富增长的贡献约占三分之二，并直接推动了证券资产类资产和新增储蓄的增长。不过2021年新增储蓄的增速放缓，较上一年度下降了约19%，但全球新增储蓄的总额仍保持在高位，达到4.8万亿欧元，比2019年高出40%。2021年新增储蓄的构成发生了轻微的变化，银行存款的份额下降了，但依然占比达到63.2%，证券以及寿险和养老金资产越来越受到青睐，分别占到15.5%和17.4%。",
//       "2021年中国家庭金融资产增长12.2%金融资产总额创历史新纪录",
//       "中国家庭金融资产总额占亚洲地区金融资产总额首次超过50%大关，如果剔除日本，占比更高达68.2%。放眼全球，中国家庭金融资产总额在全球市场占比也在10年间从7.2%攀升至13.6%。2021年，中国地区的存款和养老保险资产增速分别为10.4%和16.8%，证券资产增速提升至13.2%。值得注意的是，存款在中国家庭金融资产组合中占比达到48%，证券和理财产品占比为41.4%，保险和养老资产占比10.6%。",
//       "2021年，中国家庭负债增速再次超过资产增速，负债的增幅为13.9%。贷款增速高于名义GDP增速，债务比例(负债占GDP的百分比)升至63.2%，高于亚洲地区平均水平。与亚洲地区的其他市场一样，这个发展的重要驱动因素是房地产市场价格的上涨。因此，中国家庭的净金融资产增速略低于金融资产总增速，达到11.4%。2021年，中国人均净金融资产达15，400欧元，明显高于亚洲地区平均水平，在60个国家和地区排名位列第三十三。",
//       "安联（中国）保险控股有限公司总经理甄庆哲女士表示：“正如安联全球财富报告所说的，在中国多年来惠民富民政策措施的影响下，中国居民收入获得大幅增长，收入结构不断改善，中国家庭金融资产总额创新高。作为世界领先的金融服务集团之一，安联在中国汇集安联全球优势业务，为中国家庭提供更多创新的保险保障和资管管理解决方案，全面满足中国家庭不同阶段的金融保险需求。”",
//     ],
//   },
// ];
const moreNews = [
  {
    title: "首家合资转外资独资寿险公司中德安联人寿完成股权变更登记",
  },
  {
    title: "中德安联人寿青岛分公司全面启动2022年“金融联合教育宣传活动”",
  },
  {
    title: "中德安联人寿全面开展2022年金融联合教育宣传活动",
  },
];
export default {
  data() {
    return {
      loading: false,
      title: [],
      moreNews, //更多新闻
      content: null,
      newsTitle: "",
      time: "",
      toTitle: "",
      list: {
        AZ01501: "公司新闻",
        AZ01502: "客服公告",
        AZ01503: "公司公告",
        AZ01504: "招标公告",
      },
    };
  },

  components: { crumbs, Editor },
  mounted() {
    this.getNesDetail();
  },
  created() {
    console.log("预览");
    this.toTitle = sessionStorage.getItem("NEWS_TITLE");
    console.log(sessionStorage.getItem("NEWS_TITLE"));
    sessionStorage.setItem("news", true);

    console.log("this.$route.query.zid", this.$route.query.zid);
  },
  methods: {
    getStr(string, str) {
      console.log(string, str);
      var str_before = string.split(str)[0];
      var str_after = string.split(str)[1];
      console.log("前：" + str_before + " - 后：" + str_after);
      return str_before;
    },
    async getNesDetail() {
      this.loading = true;
      try {
        const [timeStamp, sign] = this.$getSign();
        const res = await newsDetailPre({
          timeStamp,
          sign,
          modelType: "pre",
          zid: this.$route.query.zid,
        });
        console.log("新闻详情", res.data);
        if (res.data.code == 200) {
          this.content = res.data.data.newsContent;
          this.newsTitle = res.data.data.newsTitle;
          this.time = this.getStr(res.data.data.newsDate, "T");
          console.log(this.time);
          this.navigation = res.data.data.newsType;
          console.log("11", res.data.data.newsType);
          console.log(this.list[res.data.data.newsType]);
          this.title = [
            {
              title: "新闻公告",
              link: "",
            },
            {
              title: this.list[res.data.data.newsType]
                ? this.list[res.data.data.newsType]
                : "首页新闻",
              link: "",
            },
            {
              title: "详情",
              link: `/preview/details?zid=${this.$route.query.zid}`,
            },
          ];
        }
        if (
          res.data.code == 401 ||
          res.data.code == 404 ||
          res.data.code == 500
        ) {
          this.$router.replace("/failure");
        }
      } catch (error) {
        this.$message.error(error);
        console.log(error);
      }
      this.loading = false;
    },
  },
  destroyed() {
    sessionStorage.removeItem("NEWS_TITLE")
  }

};
</script>

<style lang="less" scoped>
.newsDetails {
  .crumbs {
    background: #003781;
  }
  .main {
    // width: 60vw;
    width: 1034px;
    margin: auto;
    // text-align: center;
    > .title {
      margin: 6.7rem 0;
      font-size: 3.3rem;
      text-align: center;
      > .time {
        text-align: center;
        font-size: 1.9rem;
      }
    }

    .text {
      font-size: 14px;
      text-align: left;
      > p {
        margin: 4rem 0;
      }
    }
  }
  .line {
    width: 1034px;
    margin: auto;
    height: 1px;
    background: #dddddd;
  }
  .moreNews {
    width: 1034px;
    margin: 30px auto;
  }
  .more {
    > div {
      margin: 10px 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      > img {
        width: 15px;
        height: 10px;
        vertical-align: middle;
        margin-right: 10px;
      }
      > p {
        height: 16px;
        font-size: 14px;
        font-weight: 400;
        color: #004b98;
        line-height: 16px;
      }
    }
  }

  ::v-deep.el-breadcrumb__inner a,
  ::v-deep.el-breadcrumb__inner.is-link {
    font-weight: 700;
    text-decoration: none;
    transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    color: #fff !important;
  }
}
</style>
